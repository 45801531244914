import { useState, useEffect } from 'react'
import { Accordion, Input, Button } from 'component-library'
import AccountFormCard from '../AccountFormCard/AccountFormCard'
import { useAuth0 } from '@auth0/auth0-react'
import Modal from 'react-modal'
import DemosLayout from '../DemosLayout/DemosLayout'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import UserHandle from '../UserHandle/UserHandle'
import { get } from 'lodash'
import { useCheckBanner } from 'shared-utilities'
import ProfileSetupDropdown from '../ProfileSetupDropdown/ProfileSetupDropdown'
import CertificateManagementSection from '../CertificateManagementSection/CertificateManagementSection'
import { IProfileData } from '../../types'
import account from '../../content/account'
import MarkdownText from '../MarkdownText/MarkdownText'
import './AccountSettings.scss'

export const GET_PROFILE_DETAILS = gql(`
  query getProfile($userId: UserID!){
    profile(userId: $userId) {
      email
      firstName
      handle
      lastName
      personaOther
      personas
      id
    }
  }
`)

export const UPDATE_PROFILE_DETAILS = gql(`
  mutation profileCreate($handle: String!, $personas: [PersonaType!]!, $firstName: String, $lastName: String, $personaOther: String) {
    profileUpdate(
      model: {firstName: {value:  $firstName}, handle: {value: $handle}, lastName: {value: $lastName}, personaOther: {value: $personaOther}, personas: {value: $personas}}
    ) {
      userError {
        field
        message
      }
    }
  }
`)

export const REQUEST_PASSWORD_CHANGE = gql(`
  mutation passwordChangeTicket {
    passwordChangeTicket {
      payload
      userError {
        field
        message
      }
    }
  }
`)

export const DELETE_ACCOUNT = gql(`
  mutation profileDelete($confirm: Boolean!) {
    profileDelete(confirm: $confirm) {
      userError {
        field
        message
      }
    }
  }
`)

const {
  welcome_card,
  email_disclaimer,
  password_card,
  delete_account,
  generalErrorMessage,
} = account

const accountSideItems = [
  {
    item: 'Account',
    link: '#account',
  },
  {
    item: 'Manage Certificates',
    link: '#manage-certificates',
  },
  {
    item: 'Password',
    link: '#password',
  },
  {
    item: 'Delete Account',
    link: '#delete-account',
  },
]

const AccountSettings = () => {
  const { user } = useAuth0()

  const [isBannerPresent, setIsBannerPresent] = useState(false)
  const [userId, setUserId] = useState('')
  const [isSavedChanges, setIsSavedChanges] = useState<boolean>(false)
  const [profileErrorMessage, setProfileErrorMessage] = useState<string>('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showButtons, setShowButtons] = useState(false)
  const [isDeleteModalCloseEnabled, setDeleteModalClose] = useState(true)
  const [deleteAccountMessage, setDeleteAccountMessage] = useState({
    success: '',
    error: '',
  })
  const [passwordChangeError, setPasswordChangeError] = useState<string>('')
  const [usernameAvailable, setUsernameAvailable] = useState<boolean>(true)
  const [userPersonaError, setUserPersonaError] = useState<string>('')
  const [profileData, setProfileData] = useState<IProfileData>({
    firstName: '',
    lastName: '',
    handle: '',
    userPersona: [],
    personaOther: null,
  })

  const [originalProfileData, setOriginalProfileData] = useState<IProfileData>({
    firstName: '',
    lastName: '',
    handle: '',
    userPersona: [],
    personaOther: null,
  })

  const [
    getUserProfile,
    { data: userProfileResponse, error: userProfileError },
  ] = useLazyQuery(GET_PROFILE_DETAILS, {
    variables: { userId: userId },
  })

  const [profileUpdateMutation] = useMutation(UPDATE_PROFILE_DETAILS)
  const [passwordChangeMutation] = useMutation(REQUEST_PASSWORD_CHANGE)
  const [deleteAccountMutation] = useMutation(DELETE_ACCOUNT)

  useEffect(() => {
    if (user && user.sub) {
      setUserId(user.sub)
    }
  }, [user])

  useEffect(() => {
    if (userId) {
      getUserProfile()
    }
  }, [userId])

  useEffect(() => {
    if (userProfileResponse) {
      setProfileData({
        firstName: get(userProfileResponse, 'profile.firstName', null),
        lastName: get(userProfileResponse, 'profile.lastName', null),
        handle: get(userProfileResponse, 'profile.handle', null),
        userPersona: get(userProfileResponse, 'profile.personas', []),
        personaOther: get(userProfileResponse, 'profile.personaOther', null),
      })
      setOriginalProfileData({
        firstName: get(userProfileResponse, 'profile.firstName', null),
        lastName: get(userProfileResponse, 'profile.lastName', null),
        handle: get(userProfileResponse, 'profile.handle', null),
        userPersona: get(userProfileResponse, 'profile.personas', []),
        personaOther: get(userProfileResponse, 'profile.personaOther', null),
      })
    }
  }, [userProfileResponse, userProfileError])

  useEffect(() => {
    if (
      profileData.firstName ||
      profileData.lastName ||
      profileData.handle ||
      profileData.userPersona.length
    ) {
      setShowButtons(
        profileData.firstName !== originalProfileData.firstName ||
          profileData.lastName !== originalProfileData.lastName ||
          profileData.handle !== originalProfileData.handle ||
          profileData.personaOther !== originalProfileData.personaOther ||
          profileData.userPersona.join() !==
            originalProfileData.userPersona.join()
      )
    } else {
      setShowButtons(false)
    }
  }, [profileData, originalProfileData])

  // Disable scroll on modal popup and
  // bring focus to the first focusable element within the modal
  useEffect(() => {
    const bodyTag = document.querySelector('body')
    const modal = document.getElementById('modal')

    if (showDeleteModal) {
      bodyTag?.classList.add('overflow-hidden')
      modal?.querySelector('button')?.focus()
    } else {
      bodyTag?.classList.remove('overflow-hidden')
    }
  }, [showDeleteModal])

  useCheckBanner(setIsBannerPresent)

  const onUserPersonaChange = (userPersona: string[]) => {
    setProfileData({
      ...profileData,
      userPersona,
      personaOther: !userPersona.includes('OTHER')
        ? null
        : profileData.personaOther,
    })
  }

  // Integration logic to be added
  const onSubmitHandler = async () => {
    let hasError = false
    if (!profileData.userPersona.length) {
      setUserPersonaError('This field is required')
      hasError = true
    }
    if (!hasError) {
      try {
        const profileUpdateResponse = await profileUpdateMutation({
          variables: {
            handle: profileData.handle,
            personas: profileData.userPersona,
            firstName: profileData.firstName,
            lastName: profileData.lastName,
            personaOther: profileData.personaOther,
          },
        })
        const userErrorMessage = get(
          profileUpdateResponse,
          'data.profileUpdate.userError.message'
        )
        const genericErrorMessages = get(profileUpdateResponse, 'errors')
        if (userErrorMessage)
          setProfileErrorMessage(userErrorMessage ? userErrorMessage : '')
        else if (genericErrorMessages && genericErrorMessages.length)
          setProfileErrorMessage(genericErrorMessages[0].message)
        else {
          setProfileErrorMessage('')
          setIsSavedChanges(true)
          setOriginalProfileData({
            ...profileData,
          })
          // update sessionStorage with updated handle so profile link uses updated handle
          sessionStorage.setItem('userHandle', profileData.handle)
          window.dispatchEvent(new Event('userHandleUpdate'))
          const timer = setTimeout(() => {
            setIsSavedChanges(false)
          }, 3000)
          return () => clearTimeout(timer)
        }
      } catch (err) {
        console.error(err)
        setProfileErrorMessage(generalErrorMessage)
      }
    }
  }

  const clearData = () => {
    setShowButtons(false)
    setIsSavedChanges(false)
    setProfileErrorMessage('')
    setUsernameAvailable(true)
    setProfileData({ ...originalProfileData })
  }

  const passwordChange = async () => {
    try {
      const passwordChangeResponse = await passwordChangeMutation()

      const passwordChangeURL = get(
        passwordChangeResponse,
        'data.passwordChangeTicket.payload'
      )

      if (passwordChangeURL && typeof window !== 'undefined')
        window.open(passwordChangeURL, '_blank', 'noopener')

      const userErrorMessage = get(
        passwordChangeResponse,
        'data.passwordChangeTicket.userError.message'
      )
      const genericErrorMessages = get(passwordChangeResponse, 'errors')

      if (userErrorMessage) {
        setPasswordChangeError(userErrorMessage)
      }
      if (genericErrorMessages && genericErrorMessages.length)
        setPasswordChangeError(genericErrorMessages[0].message)
    } catch (err) {
      console.error(err)
      setPasswordChangeError(generalErrorMessage)
    }
  }

  const deleteAccount = async () => {
    try {
      setDeleteModalClose(false)
      const deleteAccountResponse = await deleteAccountMutation({
        variables: {
          confirm: true,
        },
      })

      const userErrorMessage = get(
        deleteAccountResponse,
        'data.profileDelete.userError.message'
      )
      const genericErrorMessages = get(deleteAccountResponse, 'errors')

      if (userErrorMessage) {
        setDeleteModalClose(true)
        setDeleteAccountMessage({
          ...deleteAccountMessage,
          error: userErrorMessage,
        })
      } else if (genericErrorMessages && genericErrorMessages.length) {
        setDeleteModalClose(true)
        setDeleteAccountMessage({
          ...deleteAccountMessage,
          error: `${genericErrorMessages[0].message}`,
        })
      } else {
        setDeleteAccountMessage({
          error: '',
          success: `${delete_account.successMessage}`,
        })

        // Reload the page after the successful account deletion
        const timer = setTimeout(() => {
          if (typeof window !== 'undefined') window.location.reload()
        }, 4000)
        return () => clearTimeout(timer)
      }
    } catch (err) {
      console.error(err)
      setDeleteModalClose(true)
      setDeleteAccountMessage({
        ...deleteAccountMessage,
        error: generalErrorMessage,
      })
    }
  }

  const sideSection = (
    <div
      className={`AccountSidemenu ${
        isBannerPresent ? 'top-[115px]' : 'top-[65px]'
      }`}
    >
      <Accordion
        itemTitle={welcome_card.title}
        itemContent={welcome_card.description}
        isExpandedDefault={true}
      />
      <ul className="AccountSideItems">
        {accountSideItems.map((item) => (
          <li key={item.item}>
            <a
              href={item.link}
              className={
                item.item === 'Delete Account'
                  ? 'text-[#E00000] hover:text-[#E00000]'
                  : 'text-[#757575]'
              }
            >
              {item.item}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )

  const mainSection = (
    <div className="AccountSettings">
      <Accordion
        itemTitle={welcome_card.title}
        itemContent={welcome_card.description}
        isExpandedDefault={true}
        addClass="AccountAccordion__mobile"
      />

      <AccountFormCard
        cardId="account"
        title="Account"
        className="ProfileCard"
        showButtons={showButtons}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmitHandler()
        }}
        clearData={clearData}
        isSavedChanges={isSavedChanges}
        hasError={profileErrorMessage.length ? true : false}
        errorMessage={profileErrorMessage}
        submitDisabled={!usernameAvailable}
      >
        <>
          <div className="Name">
            <Input
              fieldLabel="First name"
              fieldName="firstName"
              formName="profile"
              placeholder="Enter first name"
              maxLength={25}
              type="text"
              updateValue={(value) => {
                setProfileData({
                  ...profileData,
                  firstName: value ? `${value}` : null,
                })
              }}
              value={profileData.firstName as string}
            />
            <Input
              fieldLabel="Last name"
              fieldName="lastName"
              formName="profile"
              maxLength={25}
              placeholder="Enter last name"
              type="text"
              updateValue={(value) => {
                setProfileData({
                  ...profileData,
                  lastName: value ? `${value}` : null,
                })
              }}
              value={profileData.lastName as string}
            />
          </div>
          <UserHandle
            handle={profileData.handle}
            onChange={(value) =>
              setProfileData({ ...profileData, handle: `${value}` })
            }
            assignedHandle={originalProfileData.handle}
            isUsernameAvailable={(available) =>
              setUsernameAvailable(
                profileData.handle === originalProfileData.handle
                  ? true
                  : available
              )
            }
          />
          <ProfileSetupDropdown
            onChange={(userPersona) => {
              onUserPersonaChange(userPersona)
            }}
            preSelectedValues={profileData.userPersona}
          />
          {!profileData.userPersona.length && userPersonaError && (
            <p className="text-error-1 mt-2 text-sm">{userPersonaError}</p>
          )}
          {profileData.userPersona.includes('OTHER') && (
            <Input
              fieldLabel="Other Persona"
              fieldLabelHidden
              fieldName="Other"
              formName="profile"
              placeholder="Other — Please specify..."
              required
              testId="other-persona-input"
              type="text"
              updateValue={(value) => {
                setProfileData({
                  ...profileData,
                  personaOther: value ? `${value}` : null,
                })
              }}
              value={profileData.personaOther ? profileData.personaOther : ''}
            />
          )}
          <Input
            fieldLabel="Current email address"
            fieldName="email"
            formName="email"
            type="text"
            disabled={true}
            value={user?.email as string}
            updateValue={() => null}
            className="mt-6"
          />
          <MarkdownText text={email_disclaimer} className="text-[#757575]" />
        </>
      </AccountFormCard>

      <CertificateManagementSection
        handle={profileData.handle}
        userId={userId}
        userEmail={user?.email as string}
        userFirstName={profileData.firstName as string}
        userLastName={profileData.lastName as string}
      />

      <AccountFormCard
        cardId="password"
        className="PasswordResetCard"
        title="Password"
        showButtons={false}
      >
        <>
          <p className="text-[#757575]">{password_card}</p>
          {passwordChangeError && (
            <p className="ErrorMessage">
              <i className="bx bxs-error-circle"></i>
              {passwordChangeError}
            </p>
          )}
          <div className="flex justify-end">
            <Button
              label="Request password reset"
              onClick={passwordChange}
              type="primary"
            />
          </div>
        </>
      </AccountFormCard>

      <AccountFormCard
        cardId="delete-account"
        title="Delete account"
        className="DeleteAccountCard"
        showButtons={false}
      >
        <>
          <p className="text-[#E00000] font-semibold">
            {delete_account.warning}
          </p>
          <p className="text-[#757575]">{delete_account.subtext}</p>
          <div className="flex justify-end">
            <Button
              label="Delete Account"
              onClick={() => {
                setShowDeleteModal(true)
              }}
              type="secondary"
              variation="warning"
            />
          </div>
        </>
      </AccountFormCard>
      <Modal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        shouldCloseOnEsc={isDeleteModalCloseEnabled}
        shouldCloseOnOverlayClick={isDeleteModalCloseEnabled}
        className="DeleteAccount__modal"
        id="modal"
      >
        <p className="leading-7">{delete_account.modal_text}</p>
        {deleteAccountMessage.success && (
          <p className="SuccessMessage">
            <i className="bx bxs-check-circle"></i>{' '}
            {deleteAccountMessage.success}
          </p>
        )}
        {deleteAccountMessage.error && (
          <p className="ErrorMessage">
            <i className="bx bxs-error-circle"></i>
            {deleteAccountMessage.error}
          </p>
        )}
        <div className="flex flex-wrap justify-center">
          <Button
            label="No, keep it"
            onClick={() => {
              setShowDeleteModal(false)
            }}
            type="secondary"
            variation="warning"
            className="m-2"
            disabled={deleteAccountMessage.success ? true : false}
          />
          <Button
            label="Yes, continue"
            onClick={deleteAccount}
            type="primary"
            className="m-2"
            variation="warning"
            disabled={deleteAccountMessage.success ? true : false}
          />
        </div>
      </Modal>
    </div>
  )

  return (
    <div className="Account">
      <DemosLayout mainSection={mainSection} sidebarSection={sideSection} />
    </div>
  )
}

export default AccountSettings
