import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { format } from 'date-fns'
import { useLazyQuery } from '@apollo/client'
import { Button } from 'component-library'
import {
  GET_CERTIFICATES,
  IPendingCertificate,
} from '../CertificateManagementSection'
import CertificateDetailsForm from './CertificateDetailsForm/CertificateDetailsForm'
import CertificatePreview from './CertificatePreview/CertificatePreview'
import './PendingCertificate.scss'

export const PENDING_CERTIFICATE_TEST_ID = 'pending-certificate'

interface IPendingCertificateProps {
  pendingCertificate: IPendingCertificate
  userEmail: string
  userFirstName: string
  userLastName: string
  userId: string
}

export type SelectedView =
  | 'certificate-details'
  | 'certificate-form'
  | 'certificate-preview'
  | 'certificate-accepted'

const PendingCertificates = ({
  pendingCertificate,
  userEmail,
  userFirstName,
  userLastName,
  userId,
}: IPendingCertificateProps) => {
  const [isModalOpen, toggleIsModalOpen] = useState<boolean>(false)
  const [selectedView, updateSelectedView] = useState<SelectedView>(
    'certificate-details'
  )
  const [certificatePreviewUrl, updateCertificatePreviewUrl] =
    useState<string>('')
  const [certificateEmail, updateCertificateEmail] = useState<string>(userEmail)
  const [certificateName, updateCertificateName] = useState<string>(
    `${userFirstName || ''} ${userLastName || ''}`
  )
  const [isLoading, toggleIsLoading] = useState<boolean>(false)

  const [getCertificates] = useLazyQuery(GET_CERTIFICATES, {
    variables: { userId },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    updateCertificateName(`${userFirstName || ''} ${userLastName || ''}`)
  }, [userFirstName, userLastName])

  const onCloseModal = async (selectedView: string) => {
    if (selectedView === 'certificate-accepted') await getCertificates()
    toggleIsModalOpen(false)

    // Reset `CertificatePreview`
    updateCertificatePreviewUrl('')

    // Reset `CertificateDetailsForm` fields
    updateCertificateName(`${userFirstName || ''} ${userLastName || ''}`)
    updateCertificateEmail(userEmail)

    // Reset Acceptance steps
    updateSelectedView('certificate-details')
  }

  return (
    <>
      <li
        className="flex mb-2 justify-between"
        data-testid={PENDING_CERTIFICATE_TEST_ID}
      >
        <span className="text-[14px] my-auto">
          {pendingCertificate.definition.title}
        </span>
        <button
          className="text-[14px] bg-[#008000] text-white px-2 py-1 rounded-sm my-auto"
          onClick={() => toggleIsModalOpen(true)}
        >
          Accept
        </button>
      </li>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          onCloseModal(selectedView)
        }}
        shouldCloseOnEsc={!isLoading}
        shouldCloseOnOverlayClick={!isLoading}
        className="PendingCertificate__Modal"
        preventScroll={true}
        id={`pending-certificate-modal-${pendingCertificate.definition.slug}`}
      >
        <button
          className="absolute right-3 top-2"
          onClick={() => onCloseModal(selectedView)}
          aria-label="close modal"
        >
          <i className="bx bx-x text-xl" />
        </button>
        {selectedView === 'certificate-details' && (
          <div>
            <p className="font-roboto text-center text-[#232323] text-base">
              Certificate details
            </p>
            <h2 className="mb-4">{pendingCertificate.definition.title}</h2>
            <p className="font-normal text-base text-[#232323]">
              {pendingCertificate.definition.description}
            </p>
            <p className="text-grey-2 text-sm mb-4">
              Acquired on{' '}
              {format(new Date(pendingCertificate.awardedAt), 'y/MM/dd')}
            </p>
            <Button
              type="primary"
              label="Next"
              className="w-full mb-3"
              onClick={() => updateSelectedView('certificate-form')}
            />
            <Button
              type="text"
              label="Cancel"
              className="w-full"
              onClick={() => toggleIsModalOpen(false)}
            />
          </div>
        )}
        {selectedView === 'certificate-form' && (
          <CertificateDetailsForm
            certificateId={pendingCertificate.id}
            isLoading={isLoading}
            toggleIsLoading={toggleIsLoading}
            updateCertificatePreviewUrl={updateCertificatePreviewUrl}
            updateSelectedView={updateSelectedView}
            certificateEmail={certificateEmail}
            certificateName={certificateName}
            updateCertificateEmail={updateCertificateEmail}
            updateCertificateName={updateCertificateName}
          />
        )}
        {selectedView === 'certificate-preview' && (
          <CertificatePreview
            certificateEmail={certificateEmail}
            certificateId={pendingCertificate.id}
            certificatePreviewUrl={certificatePreviewUrl}
            certificateSlug={pendingCertificate.definition.slug}
            isLoading={isLoading}
            toggleIsLoading={toggleIsLoading}
            updateSelectedView={updateSelectedView}
          />
        )}
        {selectedView === 'certificate-accepted' && (
          <div className="PendingCertificate__accepted">
            <h2 className="mb-4 text-center">Certificate accepted!</h2>
            <p className="text-grey-2 leading-6">
              You can now share the link to your verifiable digital certificate,
              download it, and choose to make it visible on your profile.
            </p>
            <Button
              type="primary"
              label="Close"
              className="w-full"
              onClick={() => {
                onCloseModal(selectedView)
              }}
            />
          </div>
        )}
      </Modal>
    </>
  )
}

export default PendingCertificates
